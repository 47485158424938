a {
  text-decoration: none;
  color: #d5d5d5;
  fill: #d5d5d5;
}

html {
  position: fixed;
  width: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(301.28deg, #370017 0%, #48dd9e 100%),
    linear-gradient(294.84deg, #5a60e4 0%, #a9d300 100%),
    linear-gradient(83.76deg, #ffd058 3.4%, #00871e 96.6%),
    radial-gradient(100% 138.69% at 100% 0%, #7700d5 0%, #002e34 100%),
    radial-gradient(70.41% 100% at 50% 0%, #d5b300 0%, #2200aa 100%);
  background-blend-mode: soft-light, difference, difference, difference, normal;
}
.card {
  background-color: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(20px);
  border-radius: 8px;
  box-shadow: 20px 20px 22px rgba(0, 0, 0, 0.2);
  font-family: 'Poppins', sans-serif;
  padding: 1rem;
  margin: 3rem 2rem;
}

.construction {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  color: papayawhip;
}

.card-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(20px);
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 1.5rem auto;
}
.desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 1;
  margin-bottom: 1rem;
}
.primary-text {
  color: #d5d5d5;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.7px;
}
.secondary-text {
  color: #c0c0c0;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  padding-top: 0.75rem;
}

.contact-box {
  color: #d5d5d5;
  margin: 1rem 0;
}

.contact-box-content {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.contact-box-content span {
  margin-left: 0.5rem;
}

@media (min-width: 576px) {
  .card-top {
    flex-direction: row;
    align-items: center;
  }
  .card-separator {
    height: 3px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 1rem 0;
    border-radius: 15px;
  }
  .desc {
    padding-left: 1rem;
    margin-bottom: 0;
  }
  .card-img {
    margin: 0;
  }
}

@media screen and (max-height: 600px) {
  .card {
    margin: 1rem 1rem;
  }
  .card-img {
    margin-bottom: 0.5rem;
  }
  .desc {
    margin-bottom: 0.5rem;
  }
  .secondary-text {
    padding-top: 0;
  }
  .contact-box {
    margin: 0.75rem 0;
  }
}
